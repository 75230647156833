<template>
    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <h2>الرمز الخاص بك</h2>
        <span>
          تم إرسال رمز الدخول إلى بريدك الإلكتروني. يرجى إدخال الرمز للوصول إلى الفعالية.
        </span>
        <div class="input-container">
          <input
            type="text"
            v-model="accessCode"
            maxlength="6"
            placeholder="أدخل الرمز"
            @input="validateInput"
            ref="codeInput"
          />
          <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
        </div>
        <button 
          :disabled="accessCode.length !== 6" 
          @click="verifyAccessCode"
        >
          تحقق
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import api from '@/services/axios';
  
  export default {
    name: 'AccessCodeModal',
    
    props: {
      workshopId: {
        type: [String, Number],
        required: true
      }
    },
    
    data() {
      return {
        showModal: true,
        accessCode: '',
        errorMessage: '',
      }
    },
  
    async created() {
      const existingCode = localStorage.getItem('access_code');
      if (existingCode) {
        try {
          await this.verifyCode(existingCode);
          this.showModal = false;
        } catch (error) {
          localStorage.removeItem('access_code');
          this.showModal = true;
        }
      }
    },
  
    mounted() {
      this.$refs.codeInput.focus();
    },
  
    methods: {
      validateInput() {
        this.accessCode = this.accessCode.replace(/[^0-9]/g, '').slice(0, 6);
        this.errorMessage = '';
      },
  
      async verifyCode(code) {
        try {
          const response = await api.post('verify_workshop_access/', {
            access_code: code,
            workshop_id: this.workshopId
          });
          
          if (response.data.status === 200) {
            localStorage.setItem('access_code', code);
            return true;
          }
          throw new Error(response.data.message || 'رمز غير صالح للفعالية المحددة');
        } catch (error) {
          const errorMsg = error.response?.data?.message || 'فشل التحقق من الرمز';
          throw new Error(errorMsg);
        }
      },
  
      async verifyAccessCode() {
        try {
          this.errorMessage = '';
          await this.verifyCode(this.accessCode);
          this.showModal = false;
          this.$emit('access-granted');
        } catch (error) {
          this.errorMessage = error.message;
          this.accessCode = '';
          this.$refs.codeInput.focus();
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    text-align: center;
  }
  
  .input-container {
    margin: 1.5rem 0;
  }
  
  input {
    width: 100%;
    padding: 0.75rem;
    font-size: 1.25rem;
    text-align: center;
    border: 2px solid #ddd;
    border-radius: 4px;
    margin-bottom: 0.5rem;
  }
  
  button {
    padding: 0.75rem 2rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .error-message {
    color: #ff0000;
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
  </style>