<template>
  <Analytics />
  <SpeedInsights />
  <router-view />

</template>

<script>
import { SpeedInsights } from '@vercel/speed-insights/vue';
import { Analytics } from '@vercel/analytics/vue';

export default {
  name: 'App',
  components: {
    SpeedInsights,
    Analytics
  }
}
</script>

<style scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  margin-top: 60px;
}

h1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  color: #FFE500;
}


</style>
