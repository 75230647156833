<template>
  <div :class="theme" id="container">
    <div id="topBar">
      <h1>تسجيـل الحضـور</h1>
    </div>
    <AccessCodeModal 
      v-if="isInitialized"
      :workshopId="workshopConfig.id"
      @access-granted="initializeApp"
    />
    <div id="wholeContainer" v-if="isAuthenticated">
      <scan-qr
        id="positionScanQr"
        @gotNewScan="gotNewScan"
        @newScan="startNewScan"
        @scanClosed="scanClosed"
        @scanError="handleScanError"
      />
      
      <div id="positionOut">
        <text-field-out
          :data="out_data"
          :valid="Boolean(scannedData)"
        />
        <div v-if="ticketInfo" class="ticket-info">
          <p><strong>Name:</strong> {{ ticketInfo.user_name }}</p>
          <p><strong>Ticket:</strong> {{ ticketInfo.ticket_name }}</p>
          <p><strong>Date:</strong> {{ ticketInfo.ticket_day }}</p>
          <p v-if="ticketInfo.ticket_note"><strong>Note:</strong> {{ ticketInfo.ticket_note }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { curr_theme } from '@/db/session_db';
import ButtonSettings from '@/components/ButtonSettings.vue';
import ScanQr from '@/components/ScanQr.vue';
import TextFieldOut from '@/components/TextFieldOut.vue';
import TextFieldLastScan from '@/components/TextFieldLastScan.vue';
import ButtonNewQr from '@/components/ButtonNewQr.vue';
import AccessCodeModal from '@/components/AccessCodeModal.vue';
import api from '@/services/axios';
import Cookies from "js-cookie";
import workshopConfig from '@/config/workshop';

export default {
  name: 'App',
  components: {
    ButtonSettings,
    ScanQr,
    TextFieldOut,
    TextFieldLastScan,
    ButtonNewQr,
    AccessCodeModal
  },

  data() {
    return {
      theme: curr_theme,
      out_data: "Scan a QR-Code",
      scannedData: null,
      lastScanTime: null,
      isScanning: false,
      ticketInfo: null,
      isAuthenticated: false,
      isInitialized: false,
      workshopConfig
    }
  },

  async created() {
    try {
      // 1. Fetch CSRF token first
      await this.fetchCsrfToken();
      
      // 2. Check for existing access code
      const accessCode = localStorage.getItem('access_code');
      if (accessCode) {
        // 3. If access code exists, verify it
        try {
          const response = await api.post('verify_workshop_access/', {
            access_code: accessCode,
            workshop_id: this.workshopConfig.id
          });
          
          if (response.data.status === 200) {
            this.isAuthenticated = true;
          } else {
            localStorage.removeItem('access_code');
          }
        } catch (error) {
          localStorage.removeItem('access_code');
        }
      }
    } catch (error) {
      console.error("Initialization error:", error);
    } finally {
      // Always show the modal after initialization is complete
      this.isInitialized = true;
    }
  },

  methods: {
    async fetchCsrfToken() {
      try {
        const response = await api.get('get-csrftoken');
        const csrfToken = response.data.csrfToken;
        Cookies.set('token', csrfToken);
        return true;
      } catch (error) {
        console.error("Failed to fetch CSRF token:", error);
        throw error;
      }
    },

    async initializeApp() {
      this.isAuthenticated = true;
    },

    gotNewScan(data) {
      this.scannedData = data;
      if (data.ticket_info) {
        this.ticketInfo = data.ticket_info;
        this.out_data = data.message;
        this.theme = data.status == 200 ? "theme-green" : "theme-red";
      } else {
        this.ticketInfo = null;
        this.out_data = data.message;
        this.theme = "theme-red";
      }
    },

    startNewScan() {
      this.isScanning = true;
      this.out_data = 'Scanning QR-Code...';
    },

    scanClosed() {
      this.isScanning = false;
      this.out_data = 'Scan a QR';
      this.scannedData = null;
      this.lastScanTime = null;
      this.ticketInfo = null;
      this.theme = curr_theme;
    },

    handleScanError(error) {
      this.out_data = error;
      this.scannedData = null;
      this.lastScanTime = null;
      this.ticketInfo = null;
    }
  }
}
</script>

<style scoped>
@media (min-aspect-ratio: 3/2) {
  body {
    background-color: #9ff;
  }
}

#topBar {
  position: relative;
  display: flex;
  margin-top: 30px;
}

h1 {
  text-align: center;
  width: calc(100%);
  transform: translateY(-30%);
}

#positionScanQr {
  position: relative;
  margin-top: 60px;
}

#positionOut {
  position: relative;
  left: 50%;
  margin-top: 20px;
  transform: translateX(-50%);
}

#wholeContainer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 80vw;
  max-width: 450px;
}
</style>