<template>
  <div :class="theme">
    <div id="button">
      <div v-if="scanner_active" id="containerStream">
        <QrStream @decode="onDecodeEmit"/>
        <button-close-stream @click="closeScanner"/>
      </div>
      
      <transition name="scan">
        <div id="badge" v-if="!scanner_active" @click="startScanner">
          <p>{{ data }}</p>
        </div>
      </transition>
    </div>
    <div class="manual-input">
      <input
        ref="manualInput"
        type="text"
        v-model="manualCode"
        placeholder="أدخل الكود يدوياً"
        @change="onManualSubmit"
      />
    </div>
  </div>
</template>

<script>
import { curr_theme } from '@/db/session_db';
import { QrStream } from 'vue3-qr-reader';
import { reactive, toRefs } from 'vue';
import ButtonCloseStream from './ButtonCloseStream.vue';
import api from '@/services/axios';
import scannerSound from '@/assets/scanner_sound.mp3';
import Cookies from "js-cookie";
import workshopConfig from '@/config/workshop';

export default {
  name: 'ButtonScanQr',
  
  components: {
    QrStream,
    ButtonCloseStream
  },

  data() {
    return {
      theme: curr_theme,
      scanner_active: false,
      manualCode: ''
    }
  },

  setup() {
    const state = reactive({
      data: "Scan QR"
    })
    
    return {
      ...toRefs(state),
    }
  },

  methods: {
    async onManualSubmit() {
      if (this.manualCode) {
        const audio = new Audio(scannerSound);
        const input = this.manualCode.trim();

        const ticketIdRegex = /^[0-9]{1,15}$/;
        const phoneInternationalRegex = /^\+[0-9]{1,15}$/;
        const emailRegex = /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/;
        const encodedRegex = /([A-Za-z0-9+/=]+)\*([A-Za-z0-9+/=]+)\*([A-Za-z0-9+/=]+)/;

        let validationType = null;

        if (phoneInternationalRegex.test(input)) {
          validationType = 'phone';
        } else if (emailRegex.test(input)) {
          validationType = 'email';
        } else if (ticketIdRegex.test(input)) {
          validationType = 'ticket';
        } else if (encodedRegex.test(input)) {
          validationType = 'encoded';
        }

        if (validationType) {
          try {
            await this.processCode(input, validationType);
            audio.play();
          } catch (error) {
            this.$emit('scanError', error.message);
          }
        } else {
          this.$emit('scanError', 'قيمة غير صالحة');
        }

        this.manualCode = '';
        this.$refs.manualInput.focus();
      }
    },

    async onDecodeEmit(encodedData) {
      const audio = new Audio(scannerSound);
      audio.play();
      await this.processCode(encodedData, 'encoded');
    },

    async processCode(code, type) {
      try {            

        const access_code = localStorage.getItem("access_code");
        if (!access_code) {
          this.$emit("scanError", "رقم التسجيل غير موجود");
          return;
        }
        const url = `attended_confirm_ticket/`;
        
        const payload = {
          workshop_id: workshopConfig.id,
          type: type,
          value: code,
          access_code: access_code
        };
        
        const response = await api.post(url, payload, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('token')
          }
        });
        
        this.$emit("gotNewScan", response.data);
        this.theme = response.data.status == 200 ? "theme-green" : "theme-red";
        this.data = "Scan Next QR";
      } catch (error) {
        console.error('Error details:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status
        });
        this.theme = "theme-red";
        
        let errorMessage = "فشل في معالجة رمز QR";
        if (error.response) {
          errorMessage = error.response.data?.message || "خطأ في الخادم";
        } else if (error.request) {
          errorMessage = "فشل الاتصال بالخادم";
        }
        this.$emit("scanError", errorMessage);
        throw error;
      }
    },

    startScanner() {
      this.scanner_active = true;
      this.$emit('newScan');
    },
    
    closeScanner() {
      this.scanner_active = false;
      this.theme = curr_theme;
      this.$emit('scanClosed');
      this.$nextTick(() => {
        this.$refs.manualInput.focus();
      });
    }
  }
}
</script>
<style scoped>
.manual-input {
  position: relative;
  margin: 20px  auto;
  width: 100%;
}

.manual-input input {
  padding: 10px;
  border: 2px solid var(--main-color);
  border-radius: 8px;
  background: var(--background-color);
  color: var(--main-color);
  font-size: 16px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

#containerStream {
  position: relative;
  height: 100%;
}

#button {
  position: relative;
  background: var(--striped-background);
  height: 80vw;
  border-radius: 15px;
  max-width: 400px;
  max-height: 400px;
  padding: 10px;
}

#badge {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 5px 30px 5px 30px;
  transform: translate(-50%, -50%);
  background-color: var(--main-color);
  border-radius: 15px;
  border: 2px solid var(--background-color);
  cursor: pointer;
  transition-delay: 0.5s;
}

p {
  color: var(--background-color);
  user-select: none;
}

.scan-enter-from, .scan-leave-to {
  opacity: 0;
}

.scan-enter-active, .scan-leave-active {
  transition: opacity 1s ease;
}
</style>
